@import "../rgbstyle/rgbstyle-mixins";
@import "../rgbstyle/rgbstyle-variables";

.el-home-responsabilidade {
    padding: 20px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f7f7f7;
    overflow-x: hidden;
    @include desktop {
        padding: 40px 0 0;
    }

    .wrapper {
        @include desktop {
            display: flex;
            justify-content: space-between;
        }

        .img {
            position: relative;
            height: 280px;
            background-size: 270px;
            background-image: url(../../imagens/home-responsabilidade/img-mobile.png);
            background-position: center;
            background-repeat: no-repeat;
            @include phablet {
                background-size: auto;
                height: 372px;
            }
            @include desktop {
                flex-grow: 0;
                flex-shrink: 0;
                width: 40%;
                height: 436px;
                background: none;
            }

            &:before {
                @include desktop {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 673px;
                    height: 436px;
                    background-image: url(../../imagens/home-responsabilidade/img-desktop.png);
                    background-position: right center;
                    background-repeat: no-repeat;
                }
            }
        }

        .info {
            @include desktop {
                width: 60%;
            }

            .frase {
                width: 100%;
                display: block;
                float: left;
                margin-bottom: 20px;
                font-family: 'Lato';
                text-align: center;
                @include desktop {
                    margin: 80px 0 0;
                }
                @include mq-min(1920) {
                    margin: valor-fluido(50, 1920) 0 0;
                }
                @include desktop {
                    width: 50%;
                }

                .titulo {
                    width: 100%;
                    display: block;
                    font-size: 25px;
                    font-weight: 300;
                    font-style: normal;
                    color: $color;
                    max-width: 215px;
                    margin: 0 auto;
                }

                b, strong {
                    display: block;
                    width: 100%;
                    color: $color_destaque;
                    font-size: 25px;
                    font-weight: 700;
                    font-style: bold;
                }
            }

            .texto {
                padding: 0;
                width: 100%;
                display: block;
                float: left;
                text-align: center;
                @include desktop {
                    padding: 0px 0 0 60px;
                    width: 50%;
                    text-align: left;
                }

                .titulo {
                    font-size: 20px;
                    font-weight: 300;
                    font-style: normal;
                    color: $color;
                    width: 100%;
                    display: block;
                    text-transform: uppercase;
                    @include desktop {
                        padding: 50px 0 0 0px;
                    }

                    &::before {
                        @include desktop {
                            position: relative;
                            content: '';
                            width: 50px;
                            height: 1px;
                            background: $color;
                            display: block;
                            top: 15px;
                            left: -56px;
                        }
                    }

                    b, strong {
                        display: block;
                        width: 100%;
                        color: $color_destaque;
                        font-size: 40px;
                        font-weight: 700;
                        font-style: bold;

                    }
                }

                .texto-editavel {
                    margin-bottom: 20px;
                    font-weight: 500;
                    color: $color;
                    @include desktop {
                        padding: 15px 0 0 0px;
                        @include mq-min(1920) {
                            font-size: 18px;
                        }
                    }
                }

                .btn-ver-mais {
                    display: inline-block;
                    border: 1px solid #8f8f8f;
                    cursor: pointer;
                    text-transform: uppercase;

                    a {
                        padding: 10px 35px;
                        display: inline-block;
                    }

                    &:hover {
                        background-color: $color_destaque;

                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}